<template>
  <div>
    <div class="text-center display-1">
      <div class="d-flex justify-center my-4">
        <svg aria-hidden="true" class="logo-width" viewBox="0 0 263.6 263.7" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2.5C90.8-.3 166.4.5 242 .1c9.4-1.3 16.6 6.7 20.9 14.1 1.2 6.6.5 13.3.6 20 0 69.2-.1 138.5 0 207.7 1 11.3-10.3 22.7-21.6 21.7-73.4-.1-146.8-.1-220.1 0-11.4 1-22.9-10.5-21.7-21.9C.2 168.3.2 95 .1 21.6-1 12 7.3 4.3 15.2.5z"
            fill="#1194F0"
          />
          <path
            d="M70.6 63.2c9.7-22.4 34.3-37.3 58.7-35.5 14.7.4 29.5 9.2 35.8 22.7-.2-6-.2-12.1-.3-18.1 12.7-.1 25.4-.1 38.2.1v149.4c.1 17.1-7.6 34.1-21.2 44.6-22.3 17.5-54 20.5-80.2 11-23.1-8.4-40.3-31.6-38.7-56.6 11.7-.1 23.4 0 35-.1 1.6 8 3.4 16.9 10.6 21.8 16.4 11.9 43.4 7 53.2-11.3 4.3-10.2 1.3-21.7 1.9-32.4-6 14-22.9 17.6-36.5 18.9-16.5-.6-33.7-5.9-45.3-18.2-11.2-10.4-16.3-25.6-17.1-40.6-3.2-18.6-2.6-38.4 5.9-55.7z"
            fill="#FFF"
          />
          <path
            d="M128.3 61.8c12.8-2.8 27.3 3.6 32.2 16.1 6.1 15.4 6.9 33.3 1.2 48.9-4.9 10.4-15 20.5-27.4 19.3-15.9 1.2-27.5-14.3-30.3-28.5-2.2-13.4-2-27.8 3.6-40.4 3.6-8.2 11.7-14.3 20.7-15.4z"
          />
          <path
            d="M128.3 61.8c12.8-2.8 27.3 3.6 32.2 16.1 6.1 15.4 6.9 33.3 1.2 48.9-4.9 10.4-15 20.5-27.4 19.3-15.9 1.2-27.5-14.3-30.3-28.5-2.2-13.4-2-27.8 3.6-40.4 3.6-8.2 11.7-14.3 20.7-15.4z"
            fill="#1194F0"
          />
        </svg>
        <div class="header__game-name ml-5 align-self-center">garry's mod</div>
      </div>

      <div>server.cfg Generator</div>
    </div>

    <v-form ref="form" class="mt-12" @submit.prevent="generateServerCfg">
      <v-btn class="mb-5" @click="useDefaultAll">Use default for all</v-btn>

      <div v-for="(cvarItem, i) of gmodCvars" :key="i">
        <v-divider v-if="i > 0 && gmodCvars[i].title" class="mt-5 divider-section" />

        <v-divider v-else-if="i > 0 && !gmodCvars[i - 1].title && gmodCvars[i].type" class="mt-5 divider-cvar" />

        <div v-if="cvarItem.title" class="my-7 headline blue--text">
          {{ cvarItem.title }}
        </div>

        <div v-else class="mt-7">
          CVar:
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <a
                v-on="on"
                :class="{
                  'link--dark': $vuetify.theme.isDark,
                  'link--light': !$vuetify.theme.isDark
                }"
                @click="copy(cvarItem.cvar)"
              >
                {{ cvarItem.cvar }}
              </a>
            </template>
            <span>Click to copy</span>
          </v-tooltip>
          <br />
          <span v-html="toHTML(cvarItem.tutorial)" />

          <v-text-field
            v-if="cvarItem.type.match(/text|number/)"
            :ref="cvarItem.cvar"
            v-model="gmodCvarsInput[cvarItem.cvar]"
            :data-default-value="cvarItem.defaultValue"
            :placeholder="getPlaceholder(cvarItem)"
            :rules="'defaultValue' in cvarItem ? rulesText : []"
            :type="cvarItem.type"
            @keydown="cvarItem.type === 'number' && onNumber($event)"
          >
            <template v-if="'defaultValue' in cvarItem" v-slot:prepend>
              <v-btn outlined @click="gmodCvarsInput[cvarItem.cvar] = cvarItem.defaultValue"> Use default </v-btn>
            </template>
          </v-text-field>

          <v-switch
            v-else-if="cvarItem.type === 'checkbox'"
            v-model="gmodCvarsInput[cvarItem.cvar]"
            :label="`Default: ${cvarItem.defaultValue ? 'Enable' : 'Disable'}`"
          />

          <v-combobox
            v-else-if="cvarItem.type === 'select'"
            :ref="cvarItem.cvar"
            v-model="gmodCvarsInput[cvarItem.cvar]"
            :data-default-value="cvarItem.defaultValue"
            :items="cvarItem.options"
            :placeholder="`Default: ${cvarItem.options.find(({ value }) => value === cvarItem.defaultValue).text}`"
            :rules="rulesSelect"
            item-text="text"
            item-value="value"
          >
            <template v-slot:prepend>
              <v-btn
                outlined
                @click="
                  gmodCvarsInput[cvarItem.cvar] = cvarItem.options.find(({ value }) => value === cvarItem.defaultValue)
                "
              >
                Use default
              </v-btn>
            </template>
          </v-combobox>

          <v-text-field v-else-if="cvarItem.type === 'email'" v-model="gmodCvarsInput[cvarItem.cvar]" />
        </div>
      </div>

      <v-btn class="mb-5" @click="useDefaultAll">Use default for all</v-btn>

      <v-checkbox
        v-model="isAlign"
        class="justify-center"
        label="Align the CVars, values and descriptions nicely in the server.cfg?"
      />

      <transition name="slide">
        <div v-show="isError" class="error--text mb-5">
          At least one required input field is empty. Either use the default values for the empty fields (by clicking
          "Use defaults for all"), or
          <v-btn color="error" outlined small @click="scrollToEmptyField"> scroll up </v-btn>
        </div>
      </transition>
      <v-btn
        :color="isError ? 'error' : ''"
        class="px-10 py-3 d-block mx-auto mb-10 display-1 text-none"
        depressed
        height="100%"
        type="submit"
      >
        Create server.cfg
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import gmodCvars from "../../data/server-cfg-cvars/gmod-cvars.json";
import { mapMutations } from "vuex";

export default {
  name: "GmodServerCfgGenerator",
  data() {
    return {
      gmodCvars,
      gmodCvarsInput: [],
      rulesText: [
        value => {
          if (typeof value === "string") {
            return value !== "" || "This cannot be empty";
          }
          return value;
        }
      ],
      rulesSelect: [option => Boolean(option?.text) || "This cannot be empty"],
      isAlign: false,
      isError: false
    };
  },
  computed: {
    gmodCvarDefaults() {
      const toCvarDefaultValuePairs = (cvarDefaults, { cvar, defaultValue = null }) => {
        if (defaultValue !== null) {
          if (typeof defaultValue === "boolean" || defaultValue === "on") {
            cvarDefaults[cvar] = defaultValue || defaultValue === "on";
          } else {
            cvarDefaults[cvar] = "";
          }
        }
        return cvarDefaults;
      };
      return this.gmodCvars.reduce(toCvarDefaultValuePairs, {});
    },
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.pathName);
    }
  },
  methods: {
    ...mapMutations(["copy"]),
    getPlaceholder(item) {
      if ("defaultValue" in item) {
        return `E.g. ${item.defaultValue}`;
      } else {
        if (item.cvar === "rcon_password") {
          return "(Recommended)";
        } else {
          return "(Optional)";
        }
      }
    },
    toHTML(tutorial) {
      // noinspection HtmlUnknownTarget
      const { bold, link } = this.$store.state.regex;
      return tutorial
        .replace(
          link,
          `<!--suppress HtmlUnknownTarget -->
<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>`
        )
        .replace(bold, `<span class="font-weight-bold">$1</span>`);
    },
    onNumber(e) {
      if (!e.key.match(/\d/)) {
        e.preventDefault();
      }
    },
    useDefaultAll() {
      for (const cvar in this.gmodCvarDefaults) {
        if (!this.gmodCvarDefaults.hasOwnProperty(cvar)) {
          return;
        }

        const isHasModifiableDefaultValue = this.gmodCvars.find(item => item.cvar === cvar).type !== "checkbox";

        if (!(isHasModifiableDefaultValue && this.gmodCvarDefaults[cvar] === "")) {
          continue;
        }
        const item = this.gmodCvars.find(item => item?.cvar === cvar);
        let { defaultValue } = this.$refs[cvar][0].$refs.input.dataset;
        if (item.type === "select") {
          // noinspection EqualityComparisonWithCoercionJS
          defaultValue = item.options.find(option => option.value == defaultValue);
        }
        this.gmodCvarsInput[cvar] = defaultValue;
      }

      this.isError = false;
    },
    scrollToEmptyField() {
      for (const cvar in this.gmodCvarDefaults) {
        if (!this.gmodCvarDefaults.hasOwnProperty(cvar)) {
          break;
        }

        const isEmpty = this.gmodCvarDefaults[cvar] === "" || this.gmodCvarDefaults[cvar] === undefined;

        if (isEmpty) {
          const ref = this.$refs[cvar][0];
          this.$vuetify.goTo(ref, {
            easing: "easeInOutQuad",
            duration: 1000,
            offset: 110
          });

          ref.$refs.input.focus();
          break;
        }
      }
    },
    generateServerCfg() {
      this.isError = !this.$refs.form.validate();
      if (this.isError) {
        return;
      }

      const gmodCvarsInput = Object.assign({}, this.gmodCvarsInput);
      for (const cvar in gmodCvarsInput) {
        if (!gmodCvarsInput.hasOwnProperty(cvar)) {
          return;
        }

        if (typeof gmodCvarsInput[cvar] === "object") {
          gmodCvarsInput[cvar] = gmodCvarsInput[cvar].value;
        }
      }

      this.$store.commit("generateServerCfg", {
        cvars: this.gmodCvars,
        cvarValues: gmodCvarsInput,
        isAlign: this.isAlign
      });
    }
  },
  created() {
    this.gmodCvarsInput = this.gmodCvarDefaults;
  }
};
</script>

<style scoped>
@font-face {
  font-family: Gmod;
  src: url("../../../public/assets/fonts/gmod/coolvetica-rg.ttf") format("truetype"),
    url("../../../public/assets/fonts/gmod/coolvetica-rg.woff") format("woff");
}

.logo-width {
  width: 95px;
}

.header__game-name {
  font-family: Gmod, Arial, sans-serif !important;
  font-size: 28px;
}

.divider-section {
  border-color: green;
}

.divider-cvar {
  border-color: blue;
}

button[type="submit"] {
  font-family: "Segoe UI", Arial, sans-serif;
  transition: color 0.25s;
}

button[type="submit"]:hover {
  color: #c5c57c;
}

/*noinspection CssUnusedSymbol*/
.slide-enter-to,
.slide-leave {
  max-height: 100px;
}

@media (prefers-color-scheme: dark) {
  .divider-cvar {
    border-color: #1194f0;
  }

  .divider-section {
    border-color: lightgreen;
  }
}
</style>
